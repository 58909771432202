import React, { useState } from 'react';
import GalleryItem from './GalleryItem'
import { useTranslation } from 'react-i18next';
import Carousel from 'better-react-carousel'
import Portfolio1 from './Portfolio1'


function Portfolio(){
    const { t, i18n } = useTranslation();
    
    const [play1, setPlay1] = useState(false);
    const [play2, setPlay2] = useState(false);
    const [play3, setPlay3] = useState(false);
    const [play4, setPlay4] = useState(false);
    const [play5, setPlay5] = useState(false);
    const [play6, setPlay6] = useState(false);
    const [play7, setPlay7] = useState(false);
    const [play8, setPlay8] = useState(false);
    const [play9, setPlay9] = useState(false);
    const [play11, setPlay11] = useState(false);
    const [play12, setPlay12] = useState(false);
    const [play13, setPlay13] = useState(false);
    const [play14, setPlay14] = useState(false);
    const [play15, setPlay15] = useState(false);
    const [play16, setPlay16] = useState(false);
    const [playHlb, setPlayHlb] = useState(false);
    const [playBay, setPlayBay] = useState(false);
   
    const [playBnf, setPlayBnf] = useState(false);
    const [playGow, setPlayGow] = useState(false);
    const [playLotf, setPlayLotf] = useState(false);
    const [playReel, setPlayReel] = useState(false);
    const [playMenu, setPlayMenu] = useState(false);

    
    function closeVideo(id){
        if (id == "hlb"){
            setPlayHlb(false)
        }
        else if (id == "bay"){
            setPlayBay(false)
        }
        else if (id == "bnf"){
            setPlayBnf(false)
        }
        else if (id == "gow"){
            setPlayGow(false)
        }
        else if (id == "lotf"){
            setPlayLotf(false)
        }
        else if (id == "reel"){
            setPlayReel(false)
        }
        else if (id == "menu"){
            setPlayMenu(false)
        }
    }

    function closeVideo1() {

        setPlay1(false)
    }

    function closeVideo2() {
   
        setPlay2(false)
    }

    function closeVideo3() {
        setPlay3(false)
    }

    function closeVideo4() {
        setPlay4(false)
    }

    function closeVideo5() {
        setPlay5(false)
    }

    function closeVideo6() {
        setPlay6(false)
    }

    function closeVideo7() {
        setPlay7(false)
    }

    function closeVideo8() {
        setPlay8(false)
    }

    function closeVideo9() {
        setPlay9(false)
    }

    function closeVideo11() {
        setPlay11(false)
    }

    function closeVideo12() {
        setPlay12(false)
    }

    function closeVideo13() {
        setPlay13(false)
    }

    function closeVideo14() {
        setPlay14(false)
    }

    function closeVideo15() {
        setPlay15(false)
    }

    function closeVideo16() {
        setPlay16(false)
    }

    function playvideo(id){


        if (id  == "1"){
        setPlay1(true)}

        else if (id == "2"){
            setPlay2(true)}

        else if (id  == "3"){
            setPlay3(true)}

        else if (id == "4"){
            setPlay4(true)}

        else if (id  == "5"){
            setPlay5(true)}

        else if (id  == "6"){
            setPlay6(true)}

        else if (id  == "7"){
            setPlay7(true)}      

        else if (id  == "8"){
            setPlay8(true)}

        else if (id  == "9"){
            setPlay9(true)}

        else if (id  == "11"){
            setPlay11(true)}

        else if (id  == "12"){
            setPlay12(true)}

        else if (id  == "13"){
            setPlay13(true)}

        else if (id  == "14"){
            setPlay14(true)}

        else if (id  == "15"){
            setPlay15(true)}

        else if (id  == "16"){
            setPlay16(true)}
        
        else if (id == "hlb"){
            setPlayHlb(true)
        }
        else if (id == "bay"){
            setPlayBay(true)
        }
        else if (id == "bnf"){
            setPlayBnf(true)
        }
        else if (id == "gow"){
            setPlayGow(true)
        }
        else if (id == "lotf"){
            setPlayLotf(true)
        }
        else if (id == "reel"){
            setPlayReel(true)
        }
        else if (id == "menu"){
            setPlayMenu(true)
            
        }
    }
   
    return(
        <section className="page-section portfolio " id="portfolio">
            <div className="container">    


          <h2 className="page-section-heading text-center text-uppercase  title mb-0 font-face-francois">Game Audio</h2> 
              

                <div className="divider-custom">
                    <div className="divider-custom-line"></div>
                    <div className="divider-custom-icon"><i className="fas fa-gamepad"></i></div>
                    <div className="divider-custom-line"></div>
                </div>


                <div className="row mt-5 mb-5 justify-content-center text-center">
                    <div  className="col-12 col-md-6 mt-2" >
                        <div className="portfolio-item mx-auto" data-bs-toggle="modal" data-bs-target="#portfolioModal7" >
                                <div   onClick={() => playvideo("reel")} className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                                    <div className="portfolio-item-caption-content text-center text-white"><i className="fas fa-play fa-3x"></i></div>
                                </div>
                                <img   className=" img-fluid " src="./images/reel3.png" alt="..." />
                        </div>
                    </div>
                </div>

                <div className="subtitles mb-5">
                    <p id="portfolioSubtitle" className="page-section-heading text-center  text-secondary pt-5 mb-5 mt-5 font-face-francois">{t('PortfolioSubt4')}</p>
                </div>

                <div className="row mt-5 mb-5 justify-content-center text-center">

                <div className="row mb-1 justify-content-center text-center">
                        <div className="col-12 col-md-4  mt-2">
                            <div className="portfolio-item mx-auto" data-bs-toggle="modal" data-bs-target="#portfolioModal6">
                                    <div onClick={() => playvideo("lotf")} className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                                        <div className="portfolio-item-caption-content text-center text-white"><i className="fas fa-play fa-3x"></i></div>
                                    </div>
                                    <img className="img-fluid" src="./images/lotf.png" alt="..." />
                            </div>
                        </div>

                        <div  className="col-12 col-md-4 mt-2">
                            <div className="portfolio-item mx-auto" data-bs-toggle="modal" data-bs-target="#portfolioModal14">
                                    <div  onClick={() => playvideo("14")} className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                                        <div className="portfolio-item-caption-content text-center text-white"><i className="fas fa-play fa-3x"></i></div>
                                    </div>
                                    <img   className="img-fluid" src="./images/ovw.png" alt="..." />
                            </div>

                        </div>
                    </div>



                     <div className="row mb-1 justify-content-center text-center">
                        <div className="col-12 col-md-4  mt-2">
                            <div className="portfolio-item mx-auto" data-bs-toggle="modal" data-bs-target="#portfolioModal15">
                                    <div onClick={() => playvideo("15")} className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                                        <div className="portfolio-item-caption-content text-center text-white"><i className="fas fa-play fa-3x"></i></div>
                                    </div>
                                    <img className="img-fluid" src="./images/nfs.png" alt="..." />
                            </div>
                        </div>

                        <div  className="col-12 col-md-4 mt-2">
                            <div className="portfolio-item mx-auto" data-bs-toggle="modal" data-bs-target="#portfolioModal5">
                                    <div  onClick={() => playvideo("gow")} className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                                        <div className="portfolio-item-caption-content text-center text-white"><i className="fas fa-play fa-3x"></i></div>
                                    </div>
                                    <img   className="img-fluid" src="./images/gow.png" alt="..." />
                            </div>

                        </div>
                    </div>

                    <div className="row mb-5 justify-content-center text-center">


                            <div  className="col-12 col-md-4 mt-2">
                                <div className="portfolio-item mx-auto" data-bs-toggle="modal" data-bs-target="#portfolioModal1">
                                        <div  onClick={() => playvideo("hlb")} className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                                            <div className="portfolio-item-caption-content text-center text-white"><i className="fas fa-play fa-3x"></i></div>
                                        </div>
                                        <img   className="img-fluid" src="./images/hlb.png" alt="..." />
                                </div>
                            </div>

                            <div className="col-12 col-md-4  mt-2">
                                <div className="portfolio-item mx-auto" data-bs-toggle="modal" data-bs-target="#portfolioModal8">
                                        <div onClick={() => playvideo("menu")} className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                                            <div className="portfolio-item-caption-content text-center text-white"><i className="fas fa-play fa-3x"></i></div>
                                        </div>
                                        <img className="img-fluid" src="./images/menu.png" alt="..." />
                                </div>
                            </div>
                    </div>


                </div> 

                <div className="row m-2 mt-5 mb-5 justify-content-center text-center">

            </div>


                <Portfolio1 closeVideo={() => closeVideo("hlb")} id= "portfolioModal1" title = {t('tituloport1')} desc1 = {t('descport11')} desc2 = {t('descport12')} source = "https://www.youtube.com/embed/FbnAdqMMQE8" play={playHlb}/>
                <Portfolio1 closeVideo={() => closeVideo("bay")} id= "portfolioModal2" title = {t('tituloport2')} source = "https://www.youtube.com/embed/f9N1wcmTjAQ" play={playBay}/>
                <Portfolio1 closeVideo={() => closeVideo("bnf")} id= "portfolioModal4" title = {t('tituloport4')} source = "https://www.youtube.com/embed/TOAqU0n_g8o" play={playBnf}/>
                <Portfolio1 closeVideo={() => closeVideo("gow")} id= "portfolioModal5" title = {t('tituloport5')} desc1 = {t('descport51')} source = "https://www.youtube.com/embed/TQjN_-YwrJc" play={playGow}/>
                <Portfolio1 closeVideo={() => closeVideo("lotf")} id= "portfolioModal6" title = {t('tituloport6')} source = "https://www.youtube.com/embed/8npgTR42CJ0" play={playLotf}/>
                <Portfolio1 closeVideo={() => closeVideo("reel")} id= "portfolioModal7"  source = "https://www.youtube.com/watch?v=i240BXHVcGI" play={playReel}/>
                <Portfolio1 closeVideo={() => closeVideo("menu")} id= "portfolioModal8"  title = {t('tituloport8')} desc1 = {t('descport81')} source = "https://www.youtube.com/embed/-EJV45CBdI4" play={playMenu}/>
                <Portfolio1 closeVideo={closeVideo11} id= "portfolioModal9"  title = {t('titulogallery11')} desc1 = {t('descgallery11')} source = "https://www.youtube.com/watch?v=lhYx7GaC-HU" play={play11}/>
                <Portfolio1 closeVideo={closeVideo12} id= "portfolioModal10"  title = {t('titulogallery12')} desc1 = {t('descgallery12')} source = "https://www.youtube.com/watch?v=3_D5MQWbuso" play={play12}/>
                <Portfolio1 closeVideo={closeVideo13} id= "portfolioModal13"  title = {t('titulogallery13')} desc1 = {t('descgallery13')} source = "https://www.youtube.com/watch?v=GIRlftmhVQ4" play={play13}/>
                <Portfolio1 closeVideo={closeVideo14} id= "portfolioModal14"  title = {t('titulogallery14')} desc1 = {t('descgallery14')} source = "https://www.youtube.com/watch?v=K6rASWzVlDM" play={play14}/>
                <Portfolio1 closeVideo={closeVideo15} id= "portfolioModal15"  title = {t('titulogallery15')} desc1 = {t('descgallery15')} source = "https://www.youtube.com/watch?v=oVpZQM01DIU" play={play15}/>
                <Portfolio1 closeVideo={closeVideo16} id= "portfolioModal16"  title = {t('titulogallery16')} desc1 = {t('descgallery16')} source = "https://www.youtube.com/watch?v=PG0L_1OSQ4A" play={play16}/>
                
                { /* implementation  */}
                
            <div className="subtitles mb-5">
                <p id="portfolioSubtitle" className="page-section-heading text-center  text-secondary mb-5 mt-5 font-face-francois">{t('PortfolioSubt5')}</p>
            </div>

            <div className="row mt-5 mb-5 justify-content-center text-center">



            { /* implementation  */}

            <div className="row mb-1 justify-content-center text-center">        
                <div className="col-12 col-md-4  mt-2">
                    <div className="portfolio-item mx-auto" data-bs-toggle="modal" data-bs-target="#portfolioModal13">
                            <div onClick={() => playvideo("13")} className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                                <div className="portfolio-item-caption-content text-center text-white"><i className="fas fa-play fa-3x"></i></div>
                            </div>
                            <img className="img-fluid" src="./images/tps.png" alt="..." />
                    </div>
                </div>

                <div  className="col-12 col-md-4 mt-2">
                    <div className="portfolio-item mx-auto" data-bs-toggle="modal" data-bs-target="#portfolioModal16">
                            <div  onClick={() => playvideo("16")} className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                                <div className="portfolio-item-caption-content text-center text-white"><i className="fas fa-play fa-3x"></i></div>
                            </div>
                            <img   className="img-fluid" src="./images/sai.png" alt="..." />
                    </div>
                </div>
            </div>




            <div className="row mb-1 justify-content-center text-center">        
                <div className="col-12 col-md-4  mt-2">
                    <div className="portfolio-item mx-auto" data-bs-toggle="modal" data-bs-target="#portfolioModal9">
                            <div onClick={() => playvideo("11")} className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                                <div className="portfolio-item-caption-content text-center text-white"><i className="fas fa-play fa-3x"></i></div>
                            </div>
                            <img className="img-fluid" src="./images/tyres.png" alt="..." />
                    </div>
                </div>

                <div  className="col-12 col-md-4 mt-2">
                    <div className="portfolio-item mx-auto" data-bs-toggle="modal" data-bs-target="#portfolioModal10">
                            <div  onClick={() => playvideo("12")} className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                                <div className="portfolio-item-caption-content text-center text-white"><i className="fas fa-play fa-3x"></i></div>
                            </div>
                            <img   className="img-fluid" src="./images/engine.png" alt="..." />
                    </div>
                </div>
            </div>

           


            </div> 







            <div className="row m-2 mt-5 mb-5 justify-content-center text-center"></div>

               { /* carrousel sound design  */}

                <div className="subtitles mb-5">
                     <p id="portfolioSubtitle" className="page-section-heading text-center  text-secondary mb-5 mt-5 font-face-francois">{t('PortfolioSubt3')}</p>
                </div>

                <div id="carrouselbox" className="row mm-2 justify-content-center  text-center">

                            
                    <Carousel cols={3}  rows={1}  gap={0} loop responsiveLayout={[
                        {
                        breakpoint: 800,
                        loop: true,
                        autoplay: 2000
                        }
                    ] }>

                        <Carousel.Item>            
                            <div  className="w-50 portfolio-item mx-auto text-center captionbox " data-bs-toggle="modal" data-bs-target="#galleryModal1">
                                <div onClick={() => playvideo("1")}  className=" portfolio-item-caption d-flex align-items-center  justify-content-center  h-100 w-100">
                                    <div  className="  portfolio-item-caption-content  text-white " ><i className="fas fa-play fa-3x"></i></div>
                                </div>
                                <img  className="img-fluid w-100"  src="./images/fortnite.png" alt="..." />
                            </div>                      
                        </Carousel.Item>
                        
                        <Carousel.Item>
                        <div className="w-50 portfolio-item mx-auto text-center captionbox " data-bs-toggle="modal" data-bs-target="#galleryModal2">
                                <div onClick={() => playvideo("2")}id="2" className=" portfolio-item-caption d-flex align-items-center  justify-content-center  h-100 w-100">
                                    <div   className="  portfolio-item-caption-content  text-white " ><i className="fas fa-play fa-3x"></i></div>
                                </div>
                                <img className="img-fluid w-100"  src="./images/lego.png" alt="..." />
                        </div>
                        </Carousel.Item>
                        <Carousel.Item>
                        <div className="w-50 portfolio-item mx-auto text-center captionbox " data-bs-toggle="modal" data-bs-target="#galleryModal3">
                                <div onClick={() => playvideo("3")} id="3" className=" portfolio-item-caption d-flex align-items-center  justify-content-center  h-100 w-100">
                                    <div   className="  portfolio-item-caption-content  text-white " ><i className="fas fa-play fa-3x"></i></div>
                                </div>
                                <img className="img-fluid w-100"  src="./images/stgcry.png" alt="..." />
                        </div>
                        </Carousel.Item>

                        <Carousel.Item>
                        
                        <div className="w-50 portfolio-item mx-auto text-center captionbox " data-bs-toggle="modal" data-bs-target="#galleryModal4">
                                <div onClick={() => playvideo("4")} id="4" className=" portfolio-item-caption d-flex align-items-center  justify-content-center  h-100 w-100">
                                    <div   className="  portfolio-item-caption-content  text-white " ><i className="fas fa-play fa-3x"></i></div>
                                </div>
                                <img className="img-fluid w-100"  src="./images/enigma.png" alt="..." />
                        </div>
                        </Carousel.Item>
                        <Carousel.Item>
                
                        <div className="w-50 portfolio-item mx-auto text-center captionbox " data-bs-toggle="modal" data-bs-target="#galleryModal5">
                                <div onClick={() => playvideo("5")}id="5" className=" portfolio-item-caption d-flex align-items-center  justify-content-center  h-100 w-100">
                                    <div   className="  portfolio-item-caption-content  text-white " ><i className="fas fa-play fa-3x"></i></div>
                                </div>
                                <img className="img-fluid w-100"  src="./images/dest2.png" alt="..." />
                        </div>
                        </Carousel.Item>
                        <Carousel.Item>
                        <div className="w-50 portfolio-item mx-auto text-center captionbox " data-bs-toggle="modal" data-bs-target="#galleryModal6">
                                <div onClick={() => playvideo("6")} id="6" className=" portfolio-item-caption d-flex align-items-center  justify-content-center  h-100 w-100">
                                    <div   className="  portfolio-item-caption-content  text-white " ><i className="fas fa-play fa-3x"></i></div>
                                </div>
                                <img className="img-fluid w-100"  src="./images/hollowk.png" alt="..." />
                        </div>
                        </Carousel.Item>


                        <Carousel.Item>
                        <div className="w-50 portfolio-item mx-auto text-center captionbox " data-bs-toggle="modal" data-bs-target="#galleryModal7">
                                <div onClick={() => playvideo("7")} id="7" className=" portfolio-item-caption d-flex align-items-center  justify-content-center  h-100 w-100">
                                    <div   className="  portfolio-item-caption-content  text-white " ><i className="fas fa-play fa-3x"></i></div>
                                </div>
                                <img className="img-fluid w-100"  src="./images/gun.png" alt="..." />
                        </div>
                        </Carousel.Item>

                        <Carousel.Item>
                        <div className="w-50 portfolio-item mx-auto text-center captionbox " data-bs-toggle="modal" data-bs-target="#galleryModal8">
                                <div onClick={() => playvideo("8")} id="8" className=" portfolio-item-caption d-flex align-items-center  justify-content-center  h-100 w-100">
                                    <div   className="  portfolio-item-caption-content  text-white " ><i className="fas fa-play fa-3x"></i></div>
                                </div>
                                <img className="img-fluid w-100"  src="./images/grape.png" alt="..." />
                        </div>
                        </Carousel.Item>

                        <Carousel.Item>
                        <div className="w-50 portfolio-item mx-auto text-center captionbox " data-bs-toggle="modal" data-bs-target="#galleryModal9">
                                <div onClick={() => playvideo("9")} id="9" className=" portfolio-item-caption d-flex align-items-center  justify-content-center  h-100 w-100">
                                    <div   className="  portfolio-item-caption-content  text-white " ><i className="fas fa-play fa-3x"></i></div>
                                </div>
                                <img className="img-fluid w-100"  src="./images/tlou.png" alt="..." />
                        </div>
                        </Carousel.Item>

                    </Carousel>


                        <GalleryItem play = {play1} closeVideo = {closeVideo1}  title= {t('titulogallery1')} desc1={t('descgallery1')} itemid="galleryModal1" source= "https://www.youtube.com/embed/tHwK7nzqFyc" />
                        <GalleryItem play = {play2} closeVideo = {closeVideo2}  title= {t('titulogallery2')} desc1={t('descgallery2')} itemid="galleryModal2" source= "https://www.youtube.com/embed/rHKHyfJe2_0" />
                        <GalleryItem play = {play3} closeVideo = {closeVideo3}  title= {t('titulogallery3')} desc1={t('descgallery3')} itemid="galleryModal3" source= "https://www.youtube.com/watch?v=qXEdp4lqaY4" />
                        <GalleryItem play = {play4} closeVideo = {closeVideo4}  title= {t('titulogallery4')} desc1={t('descgallery4')} itemid="galleryModal4" source= "https://www.youtube.com/embed/_cakaMKI_yE" />
                        <GalleryItem play = {play5} closeVideo = {closeVideo5}  title= {t('titulogallery5')} desc1={t('descgallery5')} itemid="galleryModal5" source= "https://www.youtube.com/watch?v=YULFDTKnKx0" />
                        <GalleryItem play = {play6} closeVideo = {closeVideo6}  title= {t('titulogallery6')} desc1={t('descgallery6')} itemid="galleryModal6" source= "https://www.youtube.com/embed/5Ps6cvuC6Qc" />
                        <GalleryItem play = {play7} closeVideo = {closeVideo7}  title= {t('titulogallery7')} desc1={t('descgallery7')} itemid="galleryModal7" source= "https://www.youtube.com/embed/4ZEHcIPD_ac" /> 
                        <GalleryItem play = {play8} closeVideo = {closeVideo8}  title= {t('titulogallery8')} desc1={t('descgallery8')} itemid="galleryModal8" source= "https://www.youtube.com/embed/4iVABcI5byQ" />
                        <GalleryItem play = {play9} closeVideo = {closeVideo9}  title= {t('titulogallery9')} desc1={t('descgallery9')} itemid="galleryModal9" source= "https://www.youtube.com/watch?v=FWlkjBVvZio" />
                </div>
            </div>

        </section>
           
    )
}


export default Portfolio
import React from 'react';
import { useTranslation } from 'react-i18next';

function Footer(){
    const { t, i18n } = useTranslation();

    return(

        <footer className="footer text-center" id="contacto">
            <div className="container">
                <div className="row">
            
                    <div className="col-lg-6 mb-5 mb-lg-0">
                        <h4 className="font-face-francois footertitle text-uppercase mb-4">{t('Contact me')}</h4>
                        <p className="font-face-opensans lead mb-0 mt-1">
                            <a id="contact2"  href="mailto:cecigarciaf@gmail.com">cecigarciaf@gmail.com</a>
                            
                            <br />
                          
                        </p>
                    </div>

                    <div className="col-lg-6 mb-5 mb-lg-0">
                        <h4 className="font-face-francois footertitle text-uppercase mb-4">{t('Social Media')}</h4>
                        
                        <a className="btn-outline-light btn-social mx-1" href="https://twitter.com/Ceci11Garcia" target="blank" ><i className="fab fa-fw fa-twitter"></i></a>
                      
                        <a className="btn-outline-light btn-social mx-1" href="https://www.youtube.com/@cecig" target="blank"><i className="fab fa-fw fa-youtube"></i></a>
                    </div>


                </div>
            </div>
        </footer>
    )
}

export default Footer;